<router-outlet></router-outlet>
<div class="container my-5 text-muted text-center">
  Gerenciador de credenciais Winsite<br />
  <br /><br />
  <div class="small">
    Imagine um sistema de Gestão Empresarial intuitivo, que facilita a gestão do seu negócio?
    <br>
    Ele existe! <a href="https://winsitegestao.com.br/" target="_blank">Clique aqui</a> e saiba mais.
  </div>
</div>
